<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/CNPJ</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarClosers" placeholder="Busque por nome do closer, CPF ou CNPJ" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarClosers"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Editar closers')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>

						<closer v-for="(closer, index) in pesquisa.retorno.resultado" :key="index" :closer="closer" :index="index" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalCloser -->
      <div class="modal fade" id="modalCloser" tabindex="-1" aria-labelledby="modalCloserLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalCloserLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} closer {{ modal.id == null ? '' : ('('+ modal.nomeFantasia +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i class="fal fa-home"></i> Informações básicas
									</button>
								</h2>
								<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modal.nomeFantasia" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF/CNPJ *</label>
												<input type="text" class="form-control" v-model="modal.cpfCnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" />
											</div>
											<div class="col-12 mb-3">
												<div class="form-check mb-0">
													<input type="checkbox" class="form-check-input" id="pessoaFisica" v-model="modal.pessoaFisica">
													<label class="form-check-label" for="pessoaFisica"><i class="far fa-male font-13 color-theme mx-1"></i> Pessoa fisíca</label>
												</div>
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-building color-theme font-11 me-1"></i> Razão social <span v-if="modal.pessoaFisica != true">*</span></label>
												<input type="text" class="form-control" v-model="modal.razaoSocial" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-calculator color-theme font-11 me-1"></i> Inscrição estadual</label>
												<input type="text" class="form-control" :value="'ISENTO'" disabled v-if="modal.pessoaFisica" />
												<input type="text" class="form-control" v-model="modal.inscricaoEstadual" v-mask="'#########################'" v-else />
											</div>
											<div class="col-sm-6 mb-3 mb-sm-0">
												<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
												<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
											</div>
											<div class="col-sm-6" v-if="modal.id == null">
												<label><i class="far fa-lock color-theme font-11 me-1"></i> Senha de acesso *</label>
												<input type="text" class="form-control key" v-model="modal.senha" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i class="fal fa-map-marker-alt me-3"></i>Endereço
									</button>
								</h2>
								<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
												<div class="input-group">
													<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
													<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
														<i class="fas fa-search"></i>
													</a>
												</div>
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
												<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
											</div>
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
												<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
												<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
												<input type="text" class="form-control" v-model="modal.endereco.estado" maxlength="200" />
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
												<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
											</div>
											<div class="col-sm-8">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
												<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i class="fal fa-phone-alt"></i> Telefone
									</button>
								</h2>
								<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-3 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
												<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
											</div>
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
												<v-select name="Desktop" :options="['Fixo', 'Celular', 'Whatsapp']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
													<option v-for="(option, index) in ['Fixo', 'Celular', 'Whatsapp']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-12">
												<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
												<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarCloser">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import closer from '@/components/closers/Closer.vue'

export default {
	name: 'Closers',
	data: function () {
		return {
			pesquisa: {'valor': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'id': null, 'cpfCnpj': null, 'inscricaoEstadual': null, 'nomeFantasia': null, 'razaoSocial': null, 'pessoaFisica': false, 'email': null, 'senha': null,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'lojas': [], 'modulos': [], 'contas': []
			},
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		closer
	},
	methods: {
		abrirModal : function (closer) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getCloser',
				params: {
					email: closer == null ? null : closer.email
				}
			}).then(response => {
				$('#modalCloser').modal('show')

				this.modal = {
					'id': response.data.id,
					'cpfCnpj': response.data.cpfCnpj,
					'senha': null,
					'inscricaoEstadual': response.data.inscricaoEstadual,
					'email': response.data.email,
					'nomeFantasia': response.data.nomeFantasia,
					'razaoSocial': response.data.razaoSocial,
					'pessoaFisica': response.data.pessoaFisica,
					'endereco': response.data.endereco,
					'telefone': response.data.telefone
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarClosers : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.retorno = {'loja': null, 'nome': null, 'resultado': []}

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/searchCloser',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarCloser : function () {
			if (this.modal.cpfCnpj == null || String(this.modal.cpfCnpj).trim().length == 0 || 
				(this.modal.id == null && (this.modal.senha == null || String(this.modal.senha).trim().length == 0)) ||
				this.modal.nomeFantasia == null || String(this.modal.nomeFantasia).trim().length == 0 ||
				(this.modal.pessoaFisica != true && (this.modal.razaoSocial == null || String(this.modal.razaoSocial).trim().length == 0))) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/saveCloser',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalCloser').modal('hide')
				
				this.buscarClosers()

				this.$toast.fire({
					icon: 'success',
					title: 'Closer salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 409) {
						this.$toast.fire({
							icon: 'error',
							title: 'Email já cadastrado!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>

<style scoped>
.box-left {
	border-radius: 3px 0 0 3px !important;
}
</style>